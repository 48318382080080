<template>
	<div>
		<div class="bg-white">
			<div class="padding stu-info">
				<border-title titleName="升期关系信息" />
			</div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
				<el-form-item label="升期关系名称:" prop="className">
					<el-input v-model="ruleForm.PeriodsName" placeholder="请输入升期关系名称"
						style="width: 46%;margin-left: 20px;"></el-input>


				</el-form-item>
				<el-table :data="tableData" border style="width: 50%; margin-left:20px;" class=" margin-top-lg">
					<el-table-column prop="numberPeriods" label="期数" width="180">
					</el-table-column>
					<el-table-column prop="courseTitle" label="课程名称" width="180">
					</el-table-column>
					<el-table-column label="操作" align="center">
						<template  slot-scope="{row,$index}">
							<label @click="chooseCourses">选择课程</label>
							<label @click="handleDelete(row,$index)" class="margin-left-lg">删除</label>
						</template>
					</el-table-column>
				</el-table>
				<div class=" triangle-topleft margin-tb-xl margin margin-left-lg-left padding-bottom">
					<el-button type="primary" @click="AddForm">新增期数</el-button>
				</div>
			</el-form>
		</div>
	</div>
</template>

<script>
	import BorderTitle from "@/components/border-title/index.vue"
	export default {
		components: {
			BorderTitle,
		},
		data() {
			return {
				num: 1,
				tableData: [{
					numberPeriods: '第1期',
					courseTitle: ''
				}],
				ruleForm: {
					PeriodsName: ''
				},
				rules: {
					className: [{
							required: true,
							message: '请选择升期名称',
							trigger: 'blur'
						}
					]
				}
			}
		},

		methods: {
			AddForm() {
				this.num++
				let num=this.num
				this.tableData.push({
					numberPeriods: `第${num}期`,
					courseTitle: ''
				})
			},
			handleDelete(row, index){
				 this.tableData.splice(index, 1)
			},
			chooseCourses(){
				this.$Dialog.ChooseCourses().then(res=>{ 
				})
			}
		}
	}
</script>

<style>
</style>
